@import "vars";
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Didact+Gothic&family=Jost:wght@700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  color: white;
  font-size: 62.5%;
}

* {
  font-size: 1.6rem;
}

h1, h2, h3, h4 {
  font-family: 'Dela Gothic One', cursive;
}

h1 {
  margin: 0;
  font-size: 3rem;
}

h3 {
  margin-bottom: 30px;
  font-size: 2.2rem;
}

h4 {
  font-size: 1.4rem;
  font-family: 'Jost', sans-serif;
}

ul, li, figure {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

figure {
  padding: 0;
}

a, p {
  font-family: 'Didact Gothic', sans-serif;
}
p {
  font-size: 1.6rem;
  line-height: 3rem;
}
a {
  color: white;
  text-decoration: none;
}

.app-page {
  @media only screen and (min-width: 0) {
  	header {
      width: 100%;
      top: 0;
      left: 0;
      padding: 18px 0 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      position: fixed;
      z-index: 999;
      background-color: black;
      .address-value {
        margin: 0 24px 0 auto;
        font-family: 'Didact Gothic', sans-serif;
      }
      .logo {
        width: 74px;
        margin-left: 22px;
      }
      .menu-icon {
        margin-right: 22px;
        display: block;
        margin-left: auto;
        transform: rotate(90deg);
        font-size: 4rem;
      }
      nav {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        position: fixed;
        background-color: black;
        z-index: 99;
        flex-direction: column;
        justify-content: space-between;
        ul {
          margin-top: 80px;
        }
        li a {
          margin: 24px 0;
          display: block;
          text-align: center;
          font-size: 2rem;
          font-family: 'Jost', sans-serif;
        }
        i {
          display: block;
          margin-bottom: 60px;
          opacity: 0.8;
        }
        &.active {
          transform: translateX(0);
        }
      }
    }
    section {
      padding-left: 20px;
      padding-right: 20px;
      h3 {
        margin-top: 60px;
      }
    }
    .orange-btn {
      height: 34px;
      margin: 0 24px 0 auto;
      border: 2px solid $orange;
      border-radius: 12px;
      background-color: rgba($orange, 0.4);
      color: white;
      a {
        display: inline-block;
        padding: 0 16px 2px;
      }
    }
    section.jumbotron {
      background-image: url('../assets/header-bg.jpg');
      background-position: 0 86%;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;
      > div {
        margin-top: 79px;
        padding-top: 30px;
        padding-bottom: 40px;
      }
      h2 {
        margin-bottom: 48px;
        font-weight: 100;font-family: 'Jost', sans-serif;
        font-size: 2.2rem;
        a {
          font-family: 'Jost', sans-serif;
          text-decoration: underline;
          font-size: 2.2rem;
        }
      }
      .button-gradient {
        margin: 50px auto 22px;
        display: block;
      }
      img {
        max-width: 500px;
        z-index: -1;
      }
      figure {
        width: 152px;
      }
    }
    section.buy-section {
      margin-top: -40px;
      text-align: center;
      font-family: 'Didact Gothic', sans-serif;
      #total-supply {
        font-size: 2.4rem;
      }
      div {
        span, button {
          font-size: 2rem;
        }
        span {
          margin: 0 24px;
        }
      }
      .buy-btn {
        height: 40px;
        margin: 24px 0 0;
        padding: 0 48px;
        font-family: 'Jost', sans-serif;
        font-size: 2.4rem;
        transition: background-color 0.1s ease-in-out;
        &:hover {
          background-color: darken(#f3ba40, 20%);
        }
      }
    }
    section.minting-details {
      .button-gradient {
        display: block;
        margin: 0 auto;
      }
      div.flex-center {
        > span {
          padding: 0 12px;
          font-family: 'Jost', sans-serif;
          font-size: 3rem;
          text-align: center;
          border-right: 1px solid rgba(255, 255, 255, 0.24);
          line-height: 1;
          span {
            font-size: 1.3rem;
            opacity: 0.75;
          }
        }
      }
      span.price {
        display: block;
        margin: 32px 0 24px;
        font-family: 'Jost', sans-serif;
        color: #BEBEBE;
        font-size: 1.8rem;
      }
    }
    section.roadmap {
      margin-top: 30px;
      ul {
        padding: 12px 18px;
        border-left: 1px solid #03C4FF;
        li {
          h4 {
            margin: 36px 0 4px;
            font-size: 2rem;
          }
          > p:after, > p:before {
            content: "";
            left: -28px;
            top: 6px;
            z-index: 9;
            background-color: black;
            position: absolute;
            display: block;
            border-radius: 50px;
          }
          > p:before {
            width: 30px;
            height: 30px;
            left: -32px;
            top: 1px;
            z-index: 8;
          }
          > p:after {
            width: 17px;
            height: 17px;
            border: 1px solid #03C4FF;
          }
        }
        li:first-child {
          h4 {
            margin-top: 0;
          }
        }
      }
    }
    section.faq {
      font-family: 'Didact Gothic', sans-serif;
      li {
        h4 {
          margin-bottom: 12px;
        }
        i {
          transform: scale(0.6);
        }
        p {
          margin-top: 0;
          margin-bottom: 36px;
        }
      }
    }
    section.press {
      padding: 0;
      h3 {
        padding-left: 20px;
      }
      > div {
        max-width: 320px;
        margin: 0 auto;
        align-items: flex-start;
        justify-content: center;
        a {
          width: 250px;
          &:nth-child(2) img {
            transform: scale(1.34);
          }
          span {
            width: 90px;
            margin-top: 8px;
            display: inline-block;
          }
        }
        img {
          width: 50px;
          border-radius: 50%;
          align-self: center;
          display: block;
          margin: 0 auto 8px;
        }
      }
      span {
        color: rgba(255, 255, 255, 0.8);
        img {
          margin-bottom: 14px;
          display: block;
        }
      }
      figure {
        margin: 32px auto 80px;
      }
    }
    section.distribution {
      img {
        display: block;
        margin: 0 auto;
      }
    }
    section.team {
      padding: 0;
      h3, p {
        padding: 0 20px;
      }
      img {
        width: 320px;
        margin: 32px auto 0;
        display: block;
      }
    }
    footer {
      padding-top: 30px;
      text-align: center;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      > div {
        .logo {
          width: 112px;
          margin-bottom: 70px;
        }
        a {
          color: white;
          text-decoration: none;
        }
        a, button {
          display: block;
          text-align: center;
        }
        > div button {
          width: 180px;
          margin: 0 auto 16px;
        }
        nav {
          margin: 50px 0 24px;
          a {
            margin-bottom: 34px;
            font-size: 2rem!important;
            font-weight: bold;
          }
        }
        .powered-by {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-left: 26px;
          }
        }
      }
      > p {
        padding: 12px 0;
        line-height: 3rem;
        margin-top: 30px;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        span {
          color: rgba(255, 255, 255, 0.7);
          font-size: 1.2rem;
        }
        b {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    header {
      padding-top: 0;
      padding-bottom: 0;
      .menu-icon {
        display: none;
      }
      #connect-wallet {
        padding: 0 24px;
        margin-left: auto;
        display: inline-block;
      }
      nav {
        position: relative;
        transform: translateX(0);
        display: inline-block;
        flex: 0;
        ul {
          margin-top: 0;
          margin-right: 24px;
          display: flex;
          li {
            cursor: pointer;
            margin-right: 24px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        i { display: none; }
      }
    }
    section.jumbotron {
      > div {
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        h1 {
          font-size: 4rem;
          width: 280px;
        }
        h2 {
          margin-top: 24px;
          transform: scale(1.2);
          transform-origin: left;
        }
        > div:first-child {
          width: 340px;
        }
        > div:last-child {
          width: 252px;
          p {
            margin-top: 8px;
          }
          button {
            margin-top: 24px;
          }
        }
      }
    }
    section.minting-details {
      h4 {
        font-size: 3rem;
      }
      h4 + div {
        justify-content: left;
        span {
          padding: 0 24px;
        }
        span:first-child {
          padding-left: 0!important;
        }
      }
    }
    section.faq {
      li > p {
        max-width: 600px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
  	section.jumbotron {
      padding-top: 30px;
      padding-bottom: 30px;
      > div {
        > div:first-child {
          width: 400px;
        }
        > div:last-child {
          width: 340px;
          img {
            left: 20px;
            bottom: -90px;
          }
        }
        p {
          padding-top: 24px;
          text-align: center;
          font-size: 2rem;
        }
      }
    }
    section.minting-details {
      z-index: 1;
    }
    section.jumbotron > div,
    section.minting-details,
    section.roadmap,
    section.distribution,
    section.team,
    section.faq,
    section.press,
    footer > div
    {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
    section.team, section.press  {
      h3, p {
        padding-left: 0;
      }
    }
    section.press figure {
      margin-bottom: 0;
    }
    footer {
      margin-top: -3px;
      > div {
        padding: 0 140px;
        .logo {
          margin-bottom: 130px;
        }
        nav {
          margin-top: 24px;
          transform: translateX(26px);
        }
        > div {
          display: flex;
          justify-content: space-between;
          > div {
            order: 2;
          }
        }
        .powered-by {
          top: 160px;
          left: 140px;
          position: absolute;
          display: block;
          cursor: default;
          img {
            margin-left: 0;
            margin-top: 12px;
            display: block;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    section.faq {
      li { cursor: pointer; }
    }
  	section.jumbotron {
      .sm-icons a {
        &:nth-child(2) svg:hover {
          path {
            fill: white;
          }
        }
        &:not(:nth-child(2)) svg:hover {
          path {
            stroke: white;
          }
        }
      }
    }
  }
}
