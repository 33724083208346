.button-gradient {
  width: 180px;
  padding: 12px 24px;
  border-radius: 45px;
  border: 3px solid transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #00C5FF, #C557F8);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000 inset;
  color: white;
  font-family: 'Jost', sans-serif;
  font-size: 1.2rem;
  a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.4rem;
    font-family: 'Jost', sans-serif;
  }
}
